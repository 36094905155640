import Vue from 'vue'
import axios from '@/plugins/axios'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_TESORERIA;

class Proveedores
{
	obtener_proveedores(options) {
		return axios.get(utils.url_options(baseURL+'/proveedores', options));
	}

	obtejer_proveedor(id) {
		return axios.get(baseURL+'/tesoreria/')
	}

	obtener_facturas(id, options) {
		return axios.get(utils.url_options(baseURL+'/proveedores/facturas', options));
	}

	cargar_factura(id, payload) {
		return axios.post(baseURL+'/proveedores/facturas', payload);
	}

	descargar_documentos(id, factura, nombre) {
		axios({
			url: baseURL+'/proveedores/facturas/'+factura+'/documentos'
			,method: 'GET',
			responseType: 'blob'
		})
		.then(res => {
			Vue.prototype.$log.info('res', res);

			const urlFile = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = urlFile;
			link.setAttribute('download',  nombre+'.zip');
			document.body.appendChild(link);
			link.click();
		})
		.catch(err => {
			Vue.prototype.$log.info('err', err);
			Vue.prototype.$log.info('err.response', err.response);
			let error = 'Error desconocido';

			if (err.response && err.response.data && err.response.data.message)
				error = err.response.data.message

			if (err.response && err.response.data && err.response.data.error)
				if (typeof(err.response.data.error) == 'string')
					error += '<ul><li>'+err.response.data.error+'</li></ul>';
				else {
					error += '<ul>';
					for(let i=0; i<err.response.data.error.length; i++) {
						error += '<li>'+err.response.data.error[i]+'</li>';
					}
					error += '</ul>';
				}

			Vue.prototype.$helper.showMessage('Error', error, 'error', 'alert');
		})
	}

	agregar_observaciones(id, payload) {
		return axios.post(baseURL+'/proveedores/facturas/'+id+'/observaciones', payload);
	}

	validar_factura() {
		return axios.get(baseURL+'/proveedores/empresa/validar');
	}

	cargar_empresa() {
		return axios.get(baseURL+'/proveedores/empresa/cargar')
	}

	guardar_empresa(payload) {
		return axios.post(baseURL+'/proveedores/empresa/actualizar', payload)
	}

	cargar_complementos(payload) {
		return axios.post(baseURL+'/proveedores/complementos', payload);
	}
}

export default new Proveedores();